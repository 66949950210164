import ApiInstance from './ApiInstance.js'

export default {
    getInstituteDepartments() {
    return ApiInstance({ requiresAuth: true }).get('/institute/departments');
  },
  addNewDepartment(Data) {
    return ApiInstance({ requiresAuth: true }).post('/institute/departments', Data[0]);
  },
  editCampus(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/campuses/${id}`, Data[0]);
  },
  deleteDepartment(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/institute/departments/${id}`);
  }
}
